import { default as index1IXcX0eRWMMeta } from "/usr/src/nuxt-app/src/pages/agile-coaching/index.vue?macro=true";
import { default as _91title_936Y7pLL0nEOMeta } from "/usr/src/nuxt-app/src/pages/blog/[title].vue?macro=true";
import { default as indexpTFqNEVo6iMeta } from "/usr/src/nuxt-app/src/pages/blog/index.vue?macro=true";
import { default as indexIAlMMDMjYEMeta } from "/usr/src/nuxt-app/src/pages/blog/todos/index.vue?macro=true";
import { default as indexdemWcxVy50Meta } from "/usr/src/nuxt-app/src/pages/carreiras/index.vue?macro=true";
import { default as backoffice7ldA8NsDXxMeta } from "/usr/src/nuxt-app/src/pages/casos-de-sucesso/backoffice.vue?macro=true";
import { default as custodia_45internacionalC9MmSElpj6Meta } from "/usr/src/nuxt-app/src/pages/casos-de-sucesso/custodia-internacional.vue?macro=true";
import { default as experiencia_45varejoXt0kYq3ig2Meta } from "/usr/src/nuxt-app/src/pages/casos-de-sucesso/experiencia-varejo.vue?macro=true";
import { default as faturamento_45digital4ob9LJf2OWMeta } from "/usr/src/nuxt-app/src/pages/casos-de-sucesso/faturamento-digital.vue?macro=true";
import { default as indexXhHSbzJ1Y0Meta } from "/usr/src/nuxt-app/src/pages/casos-de-sucesso/index.vue?macro=true";
import { default as painel_45gerencialWJgyy6lgJBMeta } from "/usr/src/nuxt-app/src/pages/casos-de-sucesso/painel-gerencial.vue?macro=true";
import { default as rpaDq1XER678PMeta } from "/usr/src/nuxt-app/src/pages/casos-de-sucesso/rpa.vue?macro=true";
import { default as salesforce5vUw7GI67QMeta } from "/usr/src/nuxt-app/src/pages/casos-de-sucesso/salesforce.vue?macro=true";
import { default as sapZVVTEJqv6bMeta } from "/usr/src/nuxt-app/src/pages/casos-de-sucesso/sap.vue?macro=true";
import { default as solucao_45integrada3LgXvlUcuvMeta } from "/usr/src/nuxt-app/src/pages/casos-de-sucesso/solucao-integrada.vue?macro=true";
import { default as vida_45empresarialRWtcOny7NvMeta } from "/usr/src/nuxt-app/src/pages/casos-de-sucesso/vida-empresarial.vue?macro=true";
import { default as indexONmoSaboPoMeta } from "/usr/src/nuxt-app/src/pages/celulas-ageis/index.vue?macro=true";
import { default as indexCiLtIVXzXLMeta } from "/usr/src/nuxt-app/src/pages/clientes-e-parceiros/index.vue?macro=true";
import { default as indexeB3tLCWoIVMeta } from "/usr/src/nuxt-app/src/pages/cloud-computing/index.vue?macro=true";
import { default as indexS2D8e7RmZXMeta } from "/usr/src/nuxt-app/src/pages/codigo-de-etica-e-conduta/index.vue?macro=true";
import { default as indexrMo0JN7BQ4Meta } from "/usr/src/nuxt-app/src/pages/consultoria-estrategica/index.vue?macro=true";
import { default as indexr4DIZJE7OTMeta } from "/usr/src/nuxt-app/src/pages/contato/index.vue?macro=true";
import { default as indexr2mczKlZshMeta } from "/usr/src/nuxt-app/src/pages/desenvolvimento-web/index.vue?macro=true";
import { default as indexQ5EcfUrg4dMeta } from "/usr/src/nuxt-app/src/pages/design-thinking/index.vue?macro=true";
import { default as indexpsqySb2Q1OMeta } from "/usr/src/nuxt-app/src/pages/dev-lab/index.vue?macro=true";
import { default as indexhjgl0W442HMeta } from "/usr/src/nuxt-app/src/pages/devops/index.vue?macro=true";
import { default as indexoIpIMzV0ILMeta } from "/usr/src/nuxt-app/src/pages/erp/index.vue?macro=true";
import { default as index8gjGoRuCCiMeta } from "/usr/src/nuxt-app/src/pages/hunting/index.vue?macro=true";
import { default as indexvZiTTxwb6OMeta } from "/usr/src/nuxt-app/src/pages/index.vue?macro=true";
import { default as indexmPne1V6o8JMeta } from "/usr/src/nuxt-app/src/pages/inteligencia-aplicada/index.vue?macro=true";
import { default as indexCx1sApbrPXMeta } from "/usr/src/nuxt-app/src/pages/low-code/index.vue?macro=true";
import { default as indexGGqTqq72ANMeta } from "/usr/src/nuxt-app/src/pages/lp/bula-digital/index.vue?macro=true";
import { default as index0zjGgC3zr3Meta } from "/usr/src/nuxt-app/src/pages/midia/index.vue?macro=true";
import { default as indexoVmXOwrNT3Meta } from "/usr/src/nuxt-app/src/pages/o-que-fazemos/index.vue?macro=true";
import { default as indexJR15KYSpnaMeta } from "/usr/src/nuxt-app/src/pages/o-que-fazemos/inteligencia-sap/index.vue?macro=true";
import { default as indexHT6P5ac8MWMeta } from "/usr/src/nuxt-app/src/pages/o-que-fazemos/rpa/index.vue?macro=true";
import { default as indexC4JfthN4fwMeta } from "/usr/src/nuxt-app/src/pages/politica-de-cookies/index.vue?macro=true";
import { default as index8E2fhcM9gVMeta } from "/usr/src/nuxt-app/src/pages/politica-de-privacidade/index.vue?macro=true";
import { default as index1Gu8e7e94IMeta } from "/usr/src/nuxt-app/src/pages/programa-de-formacao/index.vue?macro=true";
import { default as indexGAUv82I3Y0Meta } from "/usr/src/nuxt-app/src/pages/quality-assurance/index.vue?macro=true";
import { default as indexzdMGiK8nGcMeta } from "/usr/src/nuxt-app/src/pages/quem-somos/index.vue?macro=true";
import { default as indexYE47yhaG7xMeta } from "/usr/src/nuxt-app/src/pages/solucoes-de-crm/index.vue?macro=true";
import { default as indexXj4fL5WcUeMeta } from "/usr/src/nuxt-app/src/pages/sustentacao-de-sistemas/index.vue?macro=true";
import { default as indexXlI5zFw6jMMeta } from "/usr/src/nuxt-app/src/pages/termos-e-condicoes-de-uso/index.vue?macro=true";
import { default as indexuLsvQHRH9sMeta } from "/usr/src/nuxt-app/src/pages/ux-ui/index.vue?macro=true";
export default [
  {
    name: index1IXcX0eRWMMeta?.name ?? "agile-coaching___pt",
    path: index1IXcX0eRWMMeta?.path ?? "/agile-coaching",
    meta: index1IXcX0eRWMMeta || {},
    alias: index1IXcX0eRWMMeta?.alias || [],
    redirect: index1IXcX0eRWMMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/agile-coaching/index.vue").then(m => m.default || m)
  },
  {
    name: index1IXcX0eRWMMeta?.name ?? "agile-coaching___en",
    path: index1IXcX0eRWMMeta?.path ?? "/en/agile-coaching",
    meta: index1IXcX0eRWMMeta || {},
    alias: index1IXcX0eRWMMeta?.alias || [],
    redirect: index1IXcX0eRWMMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/agile-coaching/index.vue").then(m => m.default || m)
  },
  {
    name: index1IXcX0eRWMMeta?.name ?? "agile-coaching___es",
    path: index1IXcX0eRWMMeta?.path ?? "/es/agile-coaching",
    meta: index1IXcX0eRWMMeta || {},
    alias: index1IXcX0eRWMMeta?.alias || [],
    redirect: index1IXcX0eRWMMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/agile-coaching/index.vue").then(m => m.default || m)
  },
  {
    name: _91title_936Y7pLL0nEOMeta?.name ?? "blog-title___pt",
    path: _91title_936Y7pLL0nEOMeta?.path ?? "/blog/:title()",
    meta: _91title_936Y7pLL0nEOMeta || {},
    alias: _91title_936Y7pLL0nEOMeta?.alias || [],
    redirect: _91title_936Y7pLL0nEOMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/blog/[title].vue").then(m => m.default || m)
  },
  {
    name: _91title_936Y7pLL0nEOMeta?.name ?? "blog-title___en",
    path: _91title_936Y7pLL0nEOMeta?.path ?? "/en/blog/:title()",
    meta: _91title_936Y7pLL0nEOMeta || {},
    alias: _91title_936Y7pLL0nEOMeta?.alias || [],
    redirect: _91title_936Y7pLL0nEOMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/blog/[title].vue").then(m => m.default || m)
  },
  {
    name: _91title_936Y7pLL0nEOMeta?.name ?? "blog-title___es",
    path: _91title_936Y7pLL0nEOMeta?.path ?? "/es/blog/:title()",
    meta: _91title_936Y7pLL0nEOMeta || {},
    alias: _91title_936Y7pLL0nEOMeta?.alias || [],
    redirect: _91title_936Y7pLL0nEOMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/blog/[title].vue").then(m => m.default || m)
  },
  {
    name: indexpTFqNEVo6iMeta?.name ?? "blog___pt",
    path: indexpTFqNEVo6iMeta?.path ?? "/blog",
    meta: indexpTFqNEVo6iMeta || {},
    alias: indexpTFqNEVo6iMeta?.alias || [],
    redirect: indexpTFqNEVo6iMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexpTFqNEVo6iMeta?.name ?? "blog___en",
    path: indexpTFqNEVo6iMeta?.path ?? "/en/blog",
    meta: indexpTFqNEVo6iMeta || {},
    alias: indexpTFqNEVo6iMeta?.alias || [],
    redirect: indexpTFqNEVo6iMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexpTFqNEVo6iMeta?.name ?? "blog___es",
    path: indexpTFqNEVo6iMeta?.path ?? "/es/blog",
    meta: indexpTFqNEVo6iMeta || {},
    alias: indexpTFqNEVo6iMeta?.alias || [],
    redirect: indexpTFqNEVo6iMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexIAlMMDMjYEMeta?.name ?? "blog-todos___pt",
    path: indexIAlMMDMjYEMeta?.path ?? "/blog/todos",
    meta: indexIAlMMDMjYEMeta || {},
    alias: indexIAlMMDMjYEMeta?.alias || [],
    redirect: indexIAlMMDMjYEMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/blog/todos/index.vue").then(m => m.default || m)
  },
  {
    name: indexIAlMMDMjYEMeta?.name ?? "blog-todos___en",
    path: indexIAlMMDMjYEMeta?.path ?? "/en/blog/todos",
    meta: indexIAlMMDMjYEMeta || {},
    alias: indexIAlMMDMjYEMeta?.alias || [],
    redirect: indexIAlMMDMjYEMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/blog/todos/index.vue").then(m => m.default || m)
  },
  {
    name: indexIAlMMDMjYEMeta?.name ?? "blog-todos___es",
    path: indexIAlMMDMjYEMeta?.path ?? "/es/blog/todos",
    meta: indexIAlMMDMjYEMeta || {},
    alias: indexIAlMMDMjYEMeta?.alias || [],
    redirect: indexIAlMMDMjYEMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/blog/todos/index.vue").then(m => m.default || m)
  },
  {
    name: indexdemWcxVy50Meta?.name ?? "carreiras___pt",
    path: indexdemWcxVy50Meta?.path ?? "/carreiras",
    meta: indexdemWcxVy50Meta || {},
    alias: indexdemWcxVy50Meta?.alias || [],
    redirect: indexdemWcxVy50Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/carreiras/index.vue").then(m => m.default || m)
  },
  {
    name: indexdemWcxVy50Meta?.name ?? "carreiras___en",
    path: indexdemWcxVy50Meta?.path ?? "/en/carreiras",
    meta: indexdemWcxVy50Meta || {},
    alias: indexdemWcxVy50Meta?.alias || [],
    redirect: indexdemWcxVy50Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/carreiras/index.vue").then(m => m.default || m)
  },
  {
    name: indexdemWcxVy50Meta?.name ?? "carreiras___es",
    path: indexdemWcxVy50Meta?.path ?? "/es/carreiras",
    meta: indexdemWcxVy50Meta || {},
    alias: indexdemWcxVy50Meta?.alias || [],
    redirect: indexdemWcxVy50Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/carreiras/index.vue").then(m => m.default || m)
  },
  {
    name: backoffice7ldA8NsDXxMeta?.name ?? "casos-de-sucesso-backoffice___pt",
    path: backoffice7ldA8NsDXxMeta?.path ?? "/casos-de-sucesso/backoffice",
    meta: backoffice7ldA8NsDXxMeta || {},
    alias: backoffice7ldA8NsDXxMeta?.alias || [],
    redirect: backoffice7ldA8NsDXxMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/backoffice.vue").then(m => m.default || m)
  },
  {
    name: backoffice7ldA8NsDXxMeta?.name ?? "casos-de-sucesso-backoffice___en",
    path: backoffice7ldA8NsDXxMeta?.path ?? "/en/casos-de-sucesso/backoffice",
    meta: backoffice7ldA8NsDXxMeta || {},
    alias: backoffice7ldA8NsDXxMeta?.alias || [],
    redirect: backoffice7ldA8NsDXxMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/backoffice.vue").then(m => m.default || m)
  },
  {
    name: backoffice7ldA8NsDXxMeta?.name ?? "casos-de-sucesso-backoffice___es",
    path: backoffice7ldA8NsDXxMeta?.path ?? "/es/casos-de-sucesso/backoffice",
    meta: backoffice7ldA8NsDXxMeta || {},
    alias: backoffice7ldA8NsDXxMeta?.alias || [],
    redirect: backoffice7ldA8NsDXxMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/backoffice.vue").then(m => m.default || m)
  },
  {
    name: custodia_45internacionalC9MmSElpj6Meta?.name ?? "casos-de-sucesso-custodia-internacional___pt",
    path: custodia_45internacionalC9MmSElpj6Meta?.path ?? "/casos-de-sucesso/custodia-internacional",
    meta: custodia_45internacionalC9MmSElpj6Meta || {},
    alias: custodia_45internacionalC9MmSElpj6Meta?.alias || [],
    redirect: custodia_45internacionalC9MmSElpj6Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/custodia-internacional.vue").then(m => m.default || m)
  },
  {
    name: custodia_45internacionalC9MmSElpj6Meta?.name ?? "casos-de-sucesso-custodia-internacional___en",
    path: custodia_45internacionalC9MmSElpj6Meta?.path ?? "/en/casos-de-sucesso/custodia-internacional",
    meta: custodia_45internacionalC9MmSElpj6Meta || {},
    alias: custodia_45internacionalC9MmSElpj6Meta?.alias || [],
    redirect: custodia_45internacionalC9MmSElpj6Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/custodia-internacional.vue").then(m => m.default || m)
  },
  {
    name: custodia_45internacionalC9MmSElpj6Meta?.name ?? "casos-de-sucesso-custodia-internacional___es",
    path: custodia_45internacionalC9MmSElpj6Meta?.path ?? "/es/casos-de-sucesso/custodia-internacional",
    meta: custodia_45internacionalC9MmSElpj6Meta || {},
    alias: custodia_45internacionalC9MmSElpj6Meta?.alias || [],
    redirect: custodia_45internacionalC9MmSElpj6Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/custodia-internacional.vue").then(m => m.default || m)
  },
  {
    name: experiencia_45varejoXt0kYq3ig2Meta?.name ?? "casos-de-sucesso-experiencia-varejo___pt",
    path: experiencia_45varejoXt0kYq3ig2Meta?.path ?? "/casos-de-sucesso/experiencia-varejo",
    meta: experiencia_45varejoXt0kYq3ig2Meta || {},
    alias: experiencia_45varejoXt0kYq3ig2Meta?.alias || [],
    redirect: experiencia_45varejoXt0kYq3ig2Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/experiencia-varejo.vue").then(m => m.default || m)
  },
  {
    name: experiencia_45varejoXt0kYq3ig2Meta?.name ?? "casos-de-sucesso-experiencia-varejo___en",
    path: experiencia_45varejoXt0kYq3ig2Meta?.path ?? "/en/casos-de-sucesso/experiencia-varejo",
    meta: experiencia_45varejoXt0kYq3ig2Meta || {},
    alias: experiencia_45varejoXt0kYq3ig2Meta?.alias || [],
    redirect: experiencia_45varejoXt0kYq3ig2Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/experiencia-varejo.vue").then(m => m.default || m)
  },
  {
    name: experiencia_45varejoXt0kYq3ig2Meta?.name ?? "casos-de-sucesso-experiencia-varejo___es",
    path: experiencia_45varejoXt0kYq3ig2Meta?.path ?? "/es/casos-de-sucesso/experiencia-varejo",
    meta: experiencia_45varejoXt0kYq3ig2Meta || {},
    alias: experiencia_45varejoXt0kYq3ig2Meta?.alias || [],
    redirect: experiencia_45varejoXt0kYq3ig2Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/experiencia-varejo.vue").then(m => m.default || m)
  },
  {
    name: faturamento_45digital4ob9LJf2OWMeta?.name ?? "casos-de-sucesso-faturamento-digital___pt",
    path: faturamento_45digital4ob9LJf2OWMeta?.path ?? "/casos-de-sucesso/faturamento-digital",
    meta: faturamento_45digital4ob9LJf2OWMeta || {},
    alias: faturamento_45digital4ob9LJf2OWMeta?.alias || [],
    redirect: faturamento_45digital4ob9LJf2OWMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/faturamento-digital.vue").then(m => m.default || m)
  },
  {
    name: faturamento_45digital4ob9LJf2OWMeta?.name ?? "casos-de-sucesso-faturamento-digital___en",
    path: faturamento_45digital4ob9LJf2OWMeta?.path ?? "/en/casos-de-sucesso/faturamento-digital",
    meta: faturamento_45digital4ob9LJf2OWMeta || {},
    alias: faturamento_45digital4ob9LJf2OWMeta?.alias || [],
    redirect: faturamento_45digital4ob9LJf2OWMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/faturamento-digital.vue").then(m => m.default || m)
  },
  {
    name: faturamento_45digital4ob9LJf2OWMeta?.name ?? "casos-de-sucesso-faturamento-digital___es",
    path: faturamento_45digital4ob9LJf2OWMeta?.path ?? "/es/casos-de-sucesso/faturamento-digital",
    meta: faturamento_45digital4ob9LJf2OWMeta || {},
    alias: faturamento_45digital4ob9LJf2OWMeta?.alias || [],
    redirect: faturamento_45digital4ob9LJf2OWMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/faturamento-digital.vue").then(m => m.default || m)
  },
  {
    name: indexXhHSbzJ1Y0Meta?.name ?? "casos-de-sucesso___pt",
    path: indexXhHSbzJ1Y0Meta?.path ?? "/casos-de-sucesso",
    meta: indexXhHSbzJ1Y0Meta || {},
    alias: indexXhHSbzJ1Y0Meta?.alias || [],
    redirect: indexXhHSbzJ1Y0Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/index.vue").then(m => m.default || m)
  },
  {
    name: indexXhHSbzJ1Y0Meta?.name ?? "casos-de-sucesso___en",
    path: indexXhHSbzJ1Y0Meta?.path ?? "/en/casos-de-sucesso",
    meta: indexXhHSbzJ1Y0Meta || {},
    alias: indexXhHSbzJ1Y0Meta?.alias || [],
    redirect: indexXhHSbzJ1Y0Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/index.vue").then(m => m.default || m)
  },
  {
    name: indexXhHSbzJ1Y0Meta?.name ?? "casos-de-sucesso___es",
    path: indexXhHSbzJ1Y0Meta?.path ?? "/es/casos-de-sucesso",
    meta: indexXhHSbzJ1Y0Meta || {},
    alias: indexXhHSbzJ1Y0Meta?.alias || [],
    redirect: indexXhHSbzJ1Y0Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/index.vue").then(m => m.default || m)
  },
  {
    name: painel_45gerencialWJgyy6lgJBMeta?.name ?? "casos-de-sucesso-painel-gerencial___pt",
    path: painel_45gerencialWJgyy6lgJBMeta?.path ?? "/casos-de-sucesso/painel-gerencial",
    meta: painel_45gerencialWJgyy6lgJBMeta || {},
    alias: painel_45gerencialWJgyy6lgJBMeta?.alias || [],
    redirect: painel_45gerencialWJgyy6lgJBMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/painel-gerencial.vue").then(m => m.default || m)
  },
  {
    name: painel_45gerencialWJgyy6lgJBMeta?.name ?? "casos-de-sucesso-painel-gerencial___en",
    path: painel_45gerencialWJgyy6lgJBMeta?.path ?? "/en/casos-de-sucesso/painel-gerencial",
    meta: painel_45gerencialWJgyy6lgJBMeta || {},
    alias: painel_45gerencialWJgyy6lgJBMeta?.alias || [],
    redirect: painel_45gerencialWJgyy6lgJBMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/painel-gerencial.vue").then(m => m.default || m)
  },
  {
    name: painel_45gerencialWJgyy6lgJBMeta?.name ?? "casos-de-sucesso-painel-gerencial___es",
    path: painel_45gerencialWJgyy6lgJBMeta?.path ?? "/es/casos-de-sucesso/painel-gerencial",
    meta: painel_45gerencialWJgyy6lgJBMeta || {},
    alias: painel_45gerencialWJgyy6lgJBMeta?.alias || [],
    redirect: painel_45gerencialWJgyy6lgJBMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/painel-gerencial.vue").then(m => m.default || m)
  },
  {
    name: rpaDq1XER678PMeta?.name ?? "casos-de-sucesso-rpa___pt",
    path: rpaDq1XER678PMeta?.path ?? "/casos-de-sucesso/rpa",
    meta: rpaDq1XER678PMeta || {},
    alias: rpaDq1XER678PMeta?.alias || [],
    redirect: rpaDq1XER678PMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/rpa.vue").then(m => m.default || m)
  },
  {
    name: rpaDq1XER678PMeta?.name ?? "casos-de-sucesso-rpa___en",
    path: rpaDq1XER678PMeta?.path ?? "/en/casos-de-sucesso/rpa",
    meta: rpaDq1XER678PMeta || {},
    alias: rpaDq1XER678PMeta?.alias || [],
    redirect: rpaDq1XER678PMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/rpa.vue").then(m => m.default || m)
  },
  {
    name: rpaDq1XER678PMeta?.name ?? "casos-de-sucesso-rpa___es",
    path: rpaDq1XER678PMeta?.path ?? "/es/casos-de-sucesso/rpa",
    meta: rpaDq1XER678PMeta || {},
    alias: rpaDq1XER678PMeta?.alias || [],
    redirect: rpaDq1XER678PMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/rpa.vue").then(m => m.default || m)
  },
  {
    name: salesforce5vUw7GI67QMeta?.name ?? "casos-de-sucesso-salesforce___pt",
    path: salesforce5vUw7GI67QMeta?.path ?? "/casos-de-sucesso/salesforce",
    meta: salesforce5vUw7GI67QMeta || {},
    alias: salesforce5vUw7GI67QMeta?.alias || [],
    redirect: salesforce5vUw7GI67QMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/salesforce.vue").then(m => m.default || m)
  },
  {
    name: salesforce5vUw7GI67QMeta?.name ?? "casos-de-sucesso-salesforce___en",
    path: salesforce5vUw7GI67QMeta?.path ?? "/en/casos-de-sucesso/salesforce",
    meta: salesforce5vUw7GI67QMeta || {},
    alias: salesforce5vUw7GI67QMeta?.alias || [],
    redirect: salesforce5vUw7GI67QMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/salesforce.vue").then(m => m.default || m)
  },
  {
    name: salesforce5vUw7GI67QMeta?.name ?? "casos-de-sucesso-salesforce___es",
    path: salesforce5vUw7GI67QMeta?.path ?? "/es/casos-de-sucesso/salesforce",
    meta: salesforce5vUw7GI67QMeta || {},
    alias: salesforce5vUw7GI67QMeta?.alias || [],
    redirect: salesforce5vUw7GI67QMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/salesforce.vue").then(m => m.default || m)
  },
  {
    name: sapZVVTEJqv6bMeta?.name ?? "casos-de-sucesso-sap___pt",
    path: sapZVVTEJqv6bMeta?.path ?? "/casos-de-sucesso/sap",
    meta: sapZVVTEJqv6bMeta || {},
    alias: sapZVVTEJqv6bMeta?.alias || [],
    redirect: sapZVVTEJqv6bMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/sap.vue").then(m => m.default || m)
  },
  {
    name: sapZVVTEJqv6bMeta?.name ?? "casos-de-sucesso-sap___en",
    path: sapZVVTEJqv6bMeta?.path ?? "/en/casos-de-sucesso/sap",
    meta: sapZVVTEJqv6bMeta || {},
    alias: sapZVVTEJqv6bMeta?.alias || [],
    redirect: sapZVVTEJqv6bMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/sap.vue").then(m => m.default || m)
  },
  {
    name: sapZVVTEJqv6bMeta?.name ?? "casos-de-sucesso-sap___es",
    path: sapZVVTEJqv6bMeta?.path ?? "/es/casos-de-sucesso/sap",
    meta: sapZVVTEJqv6bMeta || {},
    alias: sapZVVTEJqv6bMeta?.alias || [],
    redirect: sapZVVTEJqv6bMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/sap.vue").then(m => m.default || m)
  },
  {
    name: solucao_45integrada3LgXvlUcuvMeta?.name ?? "casos-de-sucesso-solucao-integrada___pt",
    path: solucao_45integrada3LgXvlUcuvMeta?.path ?? "/casos-de-sucesso/solucao-integrada",
    meta: solucao_45integrada3LgXvlUcuvMeta || {},
    alias: solucao_45integrada3LgXvlUcuvMeta?.alias || [],
    redirect: solucao_45integrada3LgXvlUcuvMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/solucao-integrada.vue").then(m => m.default || m)
  },
  {
    name: solucao_45integrada3LgXvlUcuvMeta?.name ?? "casos-de-sucesso-solucao-integrada___en",
    path: solucao_45integrada3LgXvlUcuvMeta?.path ?? "/en/casos-de-sucesso/solucao-integrada",
    meta: solucao_45integrada3LgXvlUcuvMeta || {},
    alias: solucao_45integrada3LgXvlUcuvMeta?.alias || [],
    redirect: solucao_45integrada3LgXvlUcuvMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/solucao-integrada.vue").then(m => m.default || m)
  },
  {
    name: solucao_45integrada3LgXvlUcuvMeta?.name ?? "casos-de-sucesso-solucao-integrada___es",
    path: solucao_45integrada3LgXvlUcuvMeta?.path ?? "/es/casos-de-sucesso/solucao-integrada",
    meta: solucao_45integrada3LgXvlUcuvMeta || {},
    alias: solucao_45integrada3LgXvlUcuvMeta?.alias || [],
    redirect: solucao_45integrada3LgXvlUcuvMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/solucao-integrada.vue").then(m => m.default || m)
  },
  {
    name: vida_45empresarialRWtcOny7NvMeta?.name ?? "casos-de-sucesso-vida-empresarial___pt",
    path: vida_45empresarialRWtcOny7NvMeta?.path ?? "/casos-de-sucesso/vida-empresarial",
    meta: vida_45empresarialRWtcOny7NvMeta || {},
    alias: vida_45empresarialRWtcOny7NvMeta?.alias || [],
    redirect: vida_45empresarialRWtcOny7NvMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/vida-empresarial.vue").then(m => m.default || m)
  },
  {
    name: vida_45empresarialRWtcOny7NvMeta?.name ?? "casos-de-sucesso-vida-empresarial___en",
    path: vida_45empresarialRWtcOny7NvMeta?.path ?? "/en/casos-de-sucesso/vida-empresarial",
    meta: vida_45empresarialRWtcOny7NvMeta || {},
    alias: vida_45empresarialRWtcOny7NvMeta?.alias || [],
    redirect: vida_45empresarialRWtcOny7NvMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/vida-empresarial.vue").then(m => m.default || m)
  },
  {
    name: vida_45empresarialRWtcOny7NvMeta?.name ?? "casos-de-sucesso-vida-empresarial___es",
    path: vida_45empresarialRWtcOny7NvMeta?.path ?? "/es/casos-de-sucesso/vida-empresarial",
    meta: vida_45empresarialRWtcOny7NvMeta || {},
    alias: vida_45empresarialRWtcOny7NvMeta?.alias || [],
    redirect: vida_45empresarialRWtcOny7NvMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/casos-de-sucesso/vida-empresarial.vue").then(m => m.default || m)
  },
  {
    name: indexONmoSaboPoMeta?.name ?? "celulas-ageis___pt",
    path: indexONmoSaboPoMeta?.path ?? "/celulas-ageis",
    meta: indexONmoSaboPoMeta || {},
    alias: indexONmoSaboPoMeta?.alias || [],
    redirect: indexONmoSaboPoMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/celulas-ageis/index.vue").then(m => m.default || m)
  },
  {
    name: indexONmoSaboPoMeta?.name ?? "celulas-ageis___en",
    path: indexONmoSaboPoMeta?.path ?? "/en/celulas-ageis",
    meta: indexONmoSaboPoMeta || {},
    alias: indexONmoSaboPoMeta?.alias || [],
    redirect: indexONmoSaboPoMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/celulas-ageis/index.vue").then(m => m.default || m)
  },
  {
    name: indexONmoSaboPoMeta?.name ?? "celulas-ageis___es",
    path: indexONmoSaboPoMeta?.path ?? "/es/celulas-ageis",
    meta: indexONmoSaboPoMeta || {},
    alias: indexONmoSaboPoMeta?.alias || [],
    redirect: indexONmoSaboPoMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/celulas-ageis/index.vue").then(m => m.default || m)
  },
  {
    name: indexCiLtIVXzXLMeta?.name ?? "clientes-e-parceiros___pt",
    path: indexCiLtIVXzXLMeta?.path ?? "/clientes-e-parceiros",
    meta: indexCiLtIVXzXLMeta || {},
    alias: indexCiLtIVXzXLMeta?.alias || [],
    redirect: indexCiLtIVXzXLMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/clientes-e-parceiros/index.vue").then(m => m.default || m)
  },
  {
    name: indexCiLtIVXzXLMeta?.name ?? "clientes-e-parceiros___en",
    path: indexCiLtIVXzXLMeta?.path ?? "/en/clientes-e-parceiros",
    meta: indexCiLtIVXzXLMeta || {},
    alias: indexCiLtIVXzXLMeta?.alias || [],
    redirect: indexCiLtIVXzXLMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/clientes-e-parceiros/index.vue").then(m => m.default || m)
  },
  {
    name: indexCiLtIVXzXLMeta?.name ?? "clientes-e-parceiros___es",
    path: indexCiLtIVXzXLMeta?.path ?? "/es/clientes-e-parceiros",
    meta: indexCiLtIVXzXLMeta || {},
    alias: indexCiLtIVXzXLMeta?.alias || [],
    redirect: indexCiLtIVXzXLMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/clientes-e-parceiros/index.vue").then(m => m.default || m)
  },
  {
    name: indexeB3tLCWoIVMeta?.name ?? "cloud-computing___pt",
    path: indexeB3tLCWoIVMeta?.path ?? "/cloud-computing",
    meta: indexeB3tLCWoIVMeta || {},
    alias: indexeB3tLCWoIVMeta?.alias || [],
    redirect: indexeB3tLCWoIVMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/cloud-computing/index.vue").then(m => m.default || m)
  },
  {
    name: indexeB3tLCWoIVMeta?.name ?? "cloud-computing___en",
    path: indexeB3tLCWoIVMeta?.path ?? "/en/cloud-computing",
    meta: indexeB3tLCWoIVMeta || {},
    alias: indexeB3tLCWoIVMeta?.alias || [],
    redirect: indexeB3tLCWoIVMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/cloud-computing/index.vue").then(m => m.default || m)
  },
  {
    name: indexeB3tLCWoIVMeta?.name ?? "cloud-computing___es",
    path: indexeB3tLCWoIVMeta?.path ?? "/es/cloud-computing",
    meta: indexeB3tLCWoIVMeta || {},
    alias: indexeB3tLCWoIVMeta?.alias || [],
    redirect: indexeB3tLCWoIVMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/cloud-computing/index.vue").then(m => m.default || m)
  },
  {
    name: indexS2D8e7RmZXMeta?.name ?? "codigo-de-etica-e-conduta___pt",
    path: indexS2D8e7RmZXMeta?.path ?? "/codigo-de-etica-e-conduta",
    meta: indexS2D8e7RmZXMeta || {},
    alias: indexS2D8e7RmZXMeta?.alias || [],
    redirect: indexS2D8e7RmZXMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/codigo-de-etica-e-conduta/index.vue").then(m => m.default || m)
  },
  {
    name: indexS2D8e7RmZXMeta?.name ?? "codigo-de-etica-e-conduta___en",
    path: indexS2D8e7RmZXMeta?.path ?? "/en/codigo-de-etica-e-conduta",
    meta: indexS2D8e7RmZXMeta || {},
    alias: indexS2D8e7RmZXMeta?.alias || [],
    redirect: indexS2D8e7RmZXMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/codigo-de-etica-e-conduta/index.vue").then(m => m.default || m)
  },
  {
    name: indexS2D8e7RmZXMeta?.name ?? "codigo-de-etica-e-conduta___es",
    path: indexS2D8e7RmZXMeta?.path ?? "/es/codigo-de-etica-e-conduta",
    meta: indexS2D8e7RmZXMeta || {},
    alias: indexS2D8e7RmZXMeta?.alias || [],
    redirect: indexS2D8e7RmZXMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/codigo-de-etica-e-conduta/index.vue").then(m => m.default || m)
  },
  {
    name: indexrMo0JN7BQ4Meta?.name ?? "consultoria-estrategica___pt",
    path: indexrMo0JN7BQ4Meta?.path ?? "/consultoria-estrategica",
    meta: indexrMo0JN7BQ4Meta || {},
    alias: indexrMo0JN7BQ4Meta?.alias || [],
    redirect: indexrMo0JN7BQ4Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/consultoria-estrategica/index.vue").then(m => m.default || m)
  },
  {
    name: indexrMo0JN7BQ4Meta?.name ?? "consultoria-estrategica___en",
    path: indexrMo0JN7BQ4Meta?.path ?? "/en/consultoria-estrategica",
    meta: indexrMo0JN7BQ4Meta || {},
    alias: indexrMo0JN7BQ4Meta?.alias || [],
    redirect: indexrMo0JN7BQ4Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/consultoria-estrategica/index.vue").then(m => m.default || m)
  },
  {
    name: indexrMo0JN7BQ4Meta?.name ?? "consultoria-estrategica___es",
    path: indexrMo0JN7BQ4Meta?.path ?? "/es/consultoria-estrategica",
    meta: indexrMo0JN7BQ4Meta || {},
    alias: indexrMo0JN7BQ4Meta?.alias || [],
    redirect: indexrMo0JN7BQ4Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/consultoria-estrategica/index.vue").then(m => m.default || m)
  },
  {
    name: indexr4DIZJE7OTMeta?.name ?? "contato___pt",
    path: indexr4DIZJE7OTMeta?.path ?? "/contato",
    meta: indexr4DIZJE7OTMeta || {},
    alias: indexr4DIZJE7OTMeta?.alias || [],
    redirect: indexr4DIZJE7OTMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/contato/index.vue").then(m => m.default || m)
  },
  {
    name: indexr4DIZJE7OTMeta?.name ?? "contato___en",
    path: indexr4DIZJE7OTMeta?.path ?? "/en/contato",
    meta: indexr4DIZJE7OTMeta || {},
    alias: indexr4DIZJE7OTMeta?.alias || [],
    redirect: indexr4DIZJE7OTMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/contato/index.vue").then(m => m.default || m)
  },
  {
    name: indexr4DIZJE7OTMeta?.name ?? "contato___es",
    path: indexr4DIZJE7OTMeta?.path ?? "/es/contato",
    meta: indexr4DIZJE7OTMeta || {},
    alias: indexr4DIZJE7OTMeta?.alias || [],
    redirect: indexr4DIZJE7OTMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/contato/index.vue").then(m => m.default || m)
  },
  {
    name: indexr2mczKlZshMeta?.name ?? "desenvolvimento-web___pt",
    path: indexr2mczKlZshMeta?.path ?? "/desenvolvimento-web",
    meta: indexr2mczKlZshMeta || {},
    alias: indexr2mczKlZshMeta?.alias || [],
    redirect: indexr2mczKlZshMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/desenvolvimento-web/index.vue").then(m => m.default || m)
  },
  {
    name: indexr2mczKlZshMeta?.name ?? "desenvolvimento-web___en",
    path: indexr2mczKlZshMeta?.path ?? "/en/desenvolvimento-web",
    meta: indexr2mczKlZshMeta || {},
    alias: indexr2mczKlZshMeta?.alias || [],
    redirect: indexr2mczKlZshMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/desenvolvimento-web/index.vue").then(m => m.default || m)
  },
  {
    name: indexr2mczKlZshMeta?.name ?? "desenvolvimento-web___es",
    path: indexr2mczKlZshMeta?.path ?? "/es/desenvolvimento-web",
    meta: indexr2mczKlZshMeta || {},
    alias: indexr2mczKlZshMeta?.alias || [],
    redirect: indexr2mczKlZshMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/desenvolvimento-web/index.vue").then(m => m.default || m)
  },
  {
    name: indexQ5EcfUrg4dMeta?.name ?? "design-thinking___pt",
    path: indexQ5EcfUrg4dMeta?.path ?? "/design-thinking",
    meta: indexQ5EcfUrg4dMeta || {},
    alias: indexQ5EcfUrg4dMeta?.alias || [],
    redirect: indexQ5EcfUrg4dMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/design-thinking/index.vue").then(m => m.default || m)
  },
  {
    name: indexQ5EcfUrg4dMeta?.name ?? "design-thinking___en",
    path: indexQ5EcfUrg4dMeta?.path ?? "/en/design-thinking",
    meta: indexQ5EcfUrg4dMeta || {},
    alias: indexQ5EcfUrg4dMeta?.alias || [],
    redirect: indexQ5EcfUrg4dMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/design-thinking/index.vue").then(m => m.default || m)
  },
  {
    name: indexQ5EcfUrg4dMeta?.name ?? "design-thinking___es",
    path: indexQ5EcfUrg4dMeta?.path ?? "/es/design-thinking",
    meta: indexQ5EcfUrg4dMeta || {},
    alias: indexQ5EcfUrg4dMeta?.alias || [],
    redirect: indexQ5EcfUrg4dMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/design-thinking/index.vue").then(m => m.default || m)
  },
  {
    name: indexpsqySb2Q1OMeta?.name ?? "dev-lab___pt",
    path: indexpsqySb2Q1OMeta?.path ?? "/dev-lab",
    meta: indexpsqySb2Q1OMeta || {},
    alias: indexpsqySb2Q1OMeta?.alias || [],
    redirect: indexpsqySb2Q1OMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/dev-lab/index.vue").then(m => m.default || m)
  },
  {
    name: indexpsqySb2Q1OMeta?.name ?? "dev-lab___en",
    path: indexpsqySb2Q1OMeta?.path ?? "/en/dev-lab",
    meta: indexpsqySb2Q1OMeta || {},
    alias: indexpsqySb2Q1OMeta?.alias || [],
    redirect: indexpsqySb2Q1OMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/dev-lab/index.vue").then(m => m.default || m)
  },
  {
    name: indexpsqySb2Q1OMeta?.name ?? "dev-lab___es",
    path: indexpsqySb2Q1OMeta?.path ?? "/es/dev-lab",
    meta: indexpsqySb2Q1OMeta || {},
    alias: indexpsqySb2Q1OMeta?.alias || [],
    redirect: indexpsqySb2Q1OMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/dev-lab/index.vue").then(m => m.default || m)
  },
  {
    name: indexhjgl0W442HMeta?.name ?? "devops___pt",
    path: indexhjgl0W442HMeta?.path ?? "/devops",
    meta: indexhjgl0W442HMeta || {},
    alias: indexhjgl0W442HMeta?.alias || [],
    redirect: indexhjgl0W442HMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/devops/index.vue").then(m => m.default || m)
  },
  {
    name: indexhjgl0W442HMeta?.name ?? "devops___en",
    path: indexhjgl0W442HMeta?.path ?? "/en/devops",
    meta: indexhjgl0W442HMeta || {},
    alias: indexhjgl0W442HMeta?.alias || [],
    redirect: indexhjgl0W442HMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/devops/index.vue").then(m => m.default || m)
  },
  {
    name: indexhjgl0W442HMeta?.name ?? "devops___es",
    path: indexhjgl0W442HMeta?.path ?? "/es/devops",
    meta: indexhjgl0W442HMeta || {},
    alias: indexhjgl0W442HMeta?.alias || [],
    redirect: indexhjgl0W442HMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/devops/index.vue").then(m => m.default || m)
  },
  {
    name: indexoIpIMzV0ILMeta?.name ?? "erp___pt",
    path: indexoIpIMzV0ILMeta?.path ?? "/erp",
    meta: indexoIpIMzV0ILMeta || {},
    alias: indexoIpIMzV0ILMeta?.alias || [],
    redirect: indexoIpIMzV0ILMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/erp/index.vue").then(m => m.default || m)
  },
  {
    name: indexoIpIMzV0ILMeta?.name ?? "erp___en",
    path: indexoIpIMzV0ILMeta?.path ?? "/en/erp",
    meta: indexoIpIMzV0ILMeta || {},
    alias: indexoIpIMzV0ILMeta?.alias || [],
    redirect: indexoIpIMzV0ILMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/erp/index.vue").then(m => m.default || m)
  },
  {
    name: indexoIpIMzV0ILMeta?.name ?? "erp___es",
    path: indexoIpIMzV0ILMeta?.path ?? "/es/erp",
    meta: indexoIpIMzV0ILMeta || {},
    alias: indexoIpIMzV0ILMeta?.alias || [],
    redirect: indexoIpIMzV0ILMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/erp/index.vue").then(m => m.default || m)
  },
  {
    name: index8gjGoRuCCiMeta?.name ?? "hunting___pt",
    path: index8gjGoRuCCiMeta?.path ?? "/hunting",
    meta: index8gjGoRuCCiMeta || {},
    alias: index8gjGoRuCCiMeta?.alias || [],
    redirect: index8gjGoRuCCiMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/hunting/index.vue").then(m => m.default || m)
  },
  {
    name: index8gjGoRuCCiMeta?.name ?? "hunting___en",
    path: index8gjGoRuCCiMeta?.path ?? "/en/hunting",
    meta: index8gjGoRuCCiMeta || {},
    alias: index8gjGoRuCCiMeta?.alias || [],
    redirect: index8gjGoRuCCiMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/hunting/index.vue").then(m => m.default || m)
  },
  {
    name: index8gjGoRuCCiMeta?.name ?? "hunting___es",
    path: index8gjGoRuCCiMeta?.path ?? "/es/hunting",
    meta: index8gjGoRuCCiMeta || {},
    alias: index8gjGoRuCCiMeta?.alias || [],
    redirect: index8gjGoRuCCiMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/hunting/index.vue").then(m => m.default || m)
  },
  {
    name: indexvZiTTxwb6OMeta?.name ?? "index___pt",
    path: indexvZiTTxwb6OMeta?.path ?? "/",
    meta: indexvZiTTxwb6OMeta || {},
    alias: indexvZiTTxwb6OMeta?.alias || [],
    redirect: indexvZiTTxwb6OMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvZiTTxwb6OMeta?.name ?? "index___en",
    path: indexvZiTTxwb6OMeta?.path ?? "/en",
    meta: indexvZiTTxwb6OMeta || {},
    alias: indexvZiTTxwb6OMeta?.alias || [],
    redirect: indexvZiTTxwb6OMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexvZiTTxwb6OMeta?.name ?? "index___es",
    path: indexvZiTTxwb6OMeta?.path ?? "/es",
    meta: indexvZiTTxwb6OMeta || {},
    alias: indexvZiTTxwb6OMeta?.alias || [],
    redirect: indexvZiTTxwb6OMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexmPne1V6o8JMeta?.name ?? "inteligencia-aplicada___pt",
    path: indexmPne1V6o8JMeta?.path ?? "/inteligencia-aplicada",
    meta: indexmPne1V6o8JMeta || {},
    alias: indexmPne1V6o8JMeta?.alias || [],
    redirect: indexmPne1V6o8JMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/inteligencia-aplicada/index.vue").then(m => m.default || m)
  },
  {
    name: indexmPne1V6o8JMeta?.name ?? "inteligencia-aplicada___en",
    path: indexmPne1V6o8JMeta?.path ?? "/en/inteligencia-aplicada",
    meta: indexmPne1V6o8JMeta || {},
    alias: indexmPne1V6o8JMeta?.alias || [],
    redirect: indexmPne1V6o8JMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/inteligencia-aplicada/index.vue").then(m => m.default || m)
  },
  {
    name: indexmPne1V6o8JMeta?.name ?? "inteligencia-aplicada___es",
    path: indexmPne1V6o8JMeta?.path ?? "/es/inteligencia-aplicada",
    meta: indexmPne1V6o8JMeta || {},
    alias: indexmPne1V6o8JMeta?.alias || [],
    redirect: indexmPne1V6o8JMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/inteligencia-aplicada/index.vue").then(m => m.default || m)
  },
  {
    name: indexCx1sApbrPXMeta?.name ?? "low-code___pt",
    path: indexCx1sApbrPXMeta?.path ?? "/low-code",
    meta: indexCx1sApbrPXMeta || {},
    alias: indexCx1sApbrPXMeta?.alias || [],
    redirect: indexCx1sApbrPXMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/low-code/index.vue").then(m => m.default || m)
  },
  {
    name: indexCx1sApbrPXMeta?.name ?? "low-code___en",
    path: indexCx1sApbrPXMeta?.path ?? "/en/low-code",
    meta: indexCx1sApbrPXMeta || {},
    alias: indexCx1sApbrPXMeta?.alias || [],
    redirect: indexCx1sApbrPXMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/low-code/index.vue").then(m => m.default || m)
  },
  {
    name: indexCx1sApbrPXMeta?.name ?? "low-code___es",
    path: indexCx1sApbrPXMeta?.path ?? "/es/low-code",
    meta: indexCx1sApbrPXMeta || {},
    alias: indexCx1sApbrPXMeta?.alias || [],
    redirect: indexCx1sApbrPXMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/low-code/index.vue").then(m => m.default || m)
  },
  {
    name: indexGGqTqq72ANMeta?.name ?? "lp-bula-digital___pt",
    path: indexGGqTqq72ANMeta?.path ?? "/lp/bula-digital",
    meta: indexGGqTqq72ANMeta || {},
    alias: indexGGqTqq72ANMeta?.alias || [],
    redirect: indexGGqTqq72ANMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/lp/bula-digital/index.vue").then(m => m.default || m)
  },
  {
    name: indexGGqTqq72ANMeta?.name ?? "lp-bula-digital___en",
    path: indexGGqTqq72ANMeta?.path ?? "/en/lp/bula-digital",
    meta: indexGGqTqq72ANMeta || {},
    alias: indexGGqTqq72ANMeta?.alias || [],
    redirect: indexGGqTqq72ANMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/lp/bula-digital/index.vue").then(m => m.default || m)
  },
  {
    name: indexGGqTqq72ANMeta?.name ?? "lp-bula-digital___es",
    path: indexGGqTqq72ANMeta?.path ?? "/es/lp/bula-digital",
    meta: indexGGqTqq72ANMeta || {},
    alias: indexGGqTqq72ANMeta?.alias || [],
    redirect: indexGGqTqq72ANMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/lp/bula-digital/index.vue").then(m => m.default || m)
  },
  {
    name: index0zjGgC3zr3Meta?.name ?? "midia___pt",
    path: index0zjGgC3zr3Meta?.path ?? "/midia",
    meta: index0zjGgC3zr3Meta || {},
    alias: index0zjGgC3zr3Meta?.alias || [],
    redirect: index0zjGgC3zr3Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/midia/index.vue").then(m => m.default || m)
  },
  {
    name: index0zjGgC3zr3Meta?.name ?? "midia___en",
    path: index0zjGgC3zr3Meta?.path ?? "/en/midia",
    meta: index0zjGgC3zr3Meta || {},
    alias: index0zjGgC3zr3Meta?.alias || [],
    redirect: index0zjGgC3zr3Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/midia/index.vue").then(m => m.default || m)
  },
  {
    name: index0zjGgC3zr3Meta?.name ?? "midia___es",
    path: index0zjGgC3zr3Meta?.path ?? "/es/midia",
    meta: index0zjGgC3zr3Meta || {},
    alias: index0zjGgC3zr3Meta?.alias || [],
    redirect: index0zjGgC3zr3Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/midia/index.vue").then(m => m.default || m)
  },
  {
    name: indexoVmXOwrNT3Meta?.name ?? "o-que-fazemos___pt",
    path: indexoVmXOwrNT3Meta?.path ?? "/o-que-fazemos",
    meta: indexoVmXOwrNT3Meta || {},
    alias: indexoVmXOwrNT3Meta?.alias || [],
    redirect: indexoVmXOwrNT3Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/o-que-fazemos/index.vue").then(m => m.default || m)
  },
  {
    name: indexoVmXOwrNT3Meta?.name ?? "o-que-fazemos___en",
    path: indexoVmXOwrNT3Meta?.path ?? "/en/o-que-fazemos",
    meta: indexoVmXOwrNT3Meta || {},
    alias: indexoVmXOwrNT3Meta?.alias || [],
    redirect: indexoVmXOwrNT3Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/o-que-fazemos/index.vue").then(m => m.default || m)
  },
  {
    name: indexoVmXOwrNT3Meta?.name ?? "o-que-fazemos___es",
    path: indexoVmXOwrNT3Meta?.path ?? "/es/o-que-fazemos",
    meta: indexoVmXOwrNT3Meta || {},
    alias: indexoVmXOwrNT3Meta?.alias || [],
    redirect: indexoVmXOwrNT3Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/o-que-fazemos/index.vue").then(m => m.default || m)
  },
  {
    name: indexJR15KYSpnaMeta?.name ?? "o-que-fazemos-inteligencia-sap___pt",
    path: indexJR15KYSpnaMeta?.path ?? "/o-que-fazemos/inteligencia-sap",
    meta: indexJR15KYSpnaMeta || {},
    alias: indexJR15KYSpnaMeta?.alias || [],
    redirect: indexJR15KYSpnaMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/o-que-fazemos/inteligencia-sap/index.vue").then(m => m.default || m)
  },
  {
    name: indexJR15KYSpnaMeta?.name ?? "o-que-fazemos-inteligencia-sap___en",
    path: indexJR15KYSpnaMeta?.path ?? "/en/o-que-fazemos/inteligencia-sap",
    meta: indexJR15KYSpnaMeta || {},
    alias: indexJR15KYSpnaMeta?.alias || [],
    redirect: indexJR15KYSpnaMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/o-que-fazemos/inteligencia-sap/index.vue").then(m => m.default || m)
  },
  {
    name: indexJR15KYSpnaMeta?.name ?? "o-que-fazemos-inteligencia-sap___es",
    path: indexJR15KYSpnaMeta?.path ?? "/es/o-que-fazemos/inteligencia-sap",
    meta: indexJR15KYSpnaMeta || {},
    alias: indexJR15KYSpnaMeta?.alias || [],
    redirect: indexJR15KYSpnaMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/o-que-fazemos/inteligencia-sap/index.vue").then(m => m.default || m)
  },
  {
    name: indexHT6P5ac8MWMeta?.name ?? "o-que-fazemos-rpa___pt",
    path: indexHT6P5ac8MWMeta?.path ?? "/o-que-fazemos/rpa",
    meta: indexHT6P5ac8MWMeta || {},
    alias: indexHT6P5ac8MWMeta?.alias || [],
    redirect: indexHT6P5ac8MWMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/o-que-fazemos/rpa/index.vue").then(m => m.default || m)
  },
  {
    name: indexHT6P5ac8MWMeta?.name ?? "o-que-fazemos-rpa___en",
    path: indexHT6P5ac8MWMeta?.path ?? "/en/o-que-fazemos/rpa",
    meta: indexHT6P5ac8MWMeta || {},
    alias: indexHT6P5ac8MWMeta?.alias || [],
    redirect: indexHT6P5ac8MWMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/o-que-fazemos/rpa/index.vue").then(m => m.default || m)
  },
  {
    name: indexHT6P5ac8MWMeta?.name ?? "o-que-fazemos-rpa___es",
    path: indexHT6P5ac8MWMeta?.path ?? "/es/o-que-fazemos/rpa",
    meta: indexHT6P5ac8MWMeta || {},
    alias: indexHT6P5ac8MWMeta?.alias || [],
    redirect: indexHT6P5ac8MWMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/o-que-fazemos/rpa/index.vue").then(m => m.default || m)
  },
  {
    name: indexC4JfthN4fwMeta?.name ?? "politica-de-cookies___pt",
    path: indexC4JfthN4fwMeta?.path ?? "/politica-de-cookies",
    meta: indexC4JfthN4fwMeta || {},
    alias: indexC4JfthN4fwMeta?.alias || [],
    redirect: indexC4JfthN4fwMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/politica-de-cookies/index.vue").then(m => m.default || m)
  },
  {
    name: indexC4JfthN4fwMeta?.name ?? "politica-de-cookies___en",
    path: indexC4JfthN4fwMeta?.path ?? "/en/politica-de-cookies",
    meta: indexC4JfthN4fwMeta || {},
    alias: indexC4JfthN4fwMeta?.alias || [],
    redirect: indexC4JfthN4fwMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/politica-de-cookies/index.vue").then(m => m.default || m)
  },
  {
    name: indexC4JfthN4fwMeta?.name ?? "politica-de-cookies___es",
    path: indexC4JfthN4fwMeta?.path ?? "/es/politica-de-cookies",
    meta: indexC4JfthN4fwMeta || {},
    alias: indexC4JfthN4fwMeta?.alias || [],
    redirect: indexC4JfthN4fwMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/politica-de-cookies/index.vue").then(m => m.default || m)
  },
  {
    name: index8E2fhcM9gVMeta?.name ?? "politica-de-privacidade___pt",
    path: index8E2fhcM9gVMeta?.path ?? "/politica-de-privacidade",
    meta: index8E2fhcM9gVMeta || {},
    alias: index8E2fhcM9gVMeta?.alias || [],
    redirect: index8E2fhcM9gVMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/politica-de-privacidade/index.vue").then(m => m.default || m)
  },
  {
    name: index8E2fhcM9gVMeta?.name ?? "politica-de-privacidade___en",
    path: index8E2fhcM9gVMeta?.path ?? "/en/politica-de-privacidade",
    meta: index8E2fhcM9gVMeta || {},
    alias: index8E2fhcM9gVMeta?.alias || [],
    redirect: index8E2fhcM9gVMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/politica-de-privacidade/index.vue").then(m => m.default || m)
  },
  {
    name: index8E2fhcM9gVMeta?.name ?? "politica-de-privacidade___es",
    path: index8E2fhcM9gVMeta?.path ?? "/es/politica-de-privacidade",
    meta: index8E2fhcM9gVMeta || {},
    alias: index8E2fhcM9gVMeta?.alias || [],
    redirect: index8E2fhcM9gVMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/politica-de-privacidade/index.vue").then(m => m.default || m)
  },
  {
    name: index1Gu8e7e94IMeta?.name ?? "programa-de-formacao___pt",
    path: index1Gu8e7e94IMeta?.path ?? "/programa-de-formacao",
    meta: index1Gu8e7e94IMeta || {},
    alias: index1Gu8e7e94IMeta?.alias || [],
    redirect: index1Gu8e7e94IMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/programa-de-formacao/index.vue").then(m => m.default || m)
  },
  {
    name: index1Gu8e7e94IMeta?.name ?? "programa-de-formacao___en",
    path: index1Gu8e7e94IMeta?.path ?? "/en/programa-de-formacao",
    meta: index1Gu8e7e94IMeta || {},
    alias: index1Gu8e7e94IMeta?.alias || [],
    redirect: index1Gu8e7e94IMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/programa-de-formacao/index.vue").then(m => m.default || m)
  },
  {
    name: index1Gu8e7e94IMeta?.name ?? "programa-de-formacao___es",
    path: index1Gu8e7e94IMeta?.path ?? "/es/programa-de-formacao",
    meta: index1Gu8e7e94IMeta || {},
    alias: index1Gu8e7e94IMeta?.alias || [],
    redirect: index1Gu8e7e94IMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/programa-de-formacao/index.vue").then(m => m.default || m)
  },
  {
    name: indexGAUv82I3Y0Meta?.name ?? "quality-assurance___pt",
    path: indexGAUv82I3Y0Meta?.path ?? "/quality-assurance",
    meta: indexGAUv82I3Y0Meta || {},
    alias: indexGAUv82I3Y0Meta?.alias || [],
    redirect: indexGAUv82I3Y0Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/quality-assurance/index.vue").then(m => m.default || m)
  },
  {
    name: indexGAUv82I3Y0Meta?.name ?? "quality-assurance___en",
    path: indexGAUv82I3Y0Meta?.path ?? "/en/quality-assurance",
    meta: indexGAUv82I3Y0Meta || {},
    alias: indexGAUv82I3Y0Meta?.alias || [],
    redirect: indexGAUv82I3Y0Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/quality-assurance/index.vue").then(m => m.default || m)
  },
  {
    name: indexGAUv82I3Y0Meta?.name ?? "quality-assurance___es",
    path: indexGAUv82I3Y0Meta?.path ?? "/es/quality-assurance",
    meta: indexGAUv82I3Y0Meta || {},
    alias: indexGAUv82I3Y0Meta?.alias || [],
    redirect: indexGAUv82I3Y0Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/quality-assurance/index.vue").then(m => m.default || m)
  },
  {
    name: indexzdMGiK8nGcMeta?.name ?? "quem-somos___pt",
    path: indexzdMGiK8nGcMeta?.path ?? "/quem-somos",
    meta: indexzdMGiK8nGcMeta || {},
    alias: indexzdMGiK8nGcMeta?.alias || [],
    redirect: indexzdMGiK8nGcMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/quem-somos/index.vue").then(m => m.default || m)
  },
  {
    name: indexzdMGiK8nGcMeta?.name ?? "quem-somos___en",
    path: indexzdMGiK8nGcMeta?.path ?? "/en/quem-somos",
    meta: indexzdMGiK8nGcMeta || {},
    alias: indexzdMGiK8nGcMeta?.alias || [],
    redirect: indexzdMGiK8nGcMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/quem-somos/index.vue").then(m => m.default || m)
  },
  {
    name: indexzdMGiK8nGcMeta?.name ?? "quem-somos___es",
    path: indexzdMGiK8nGcMeta?.path ?? "/es/quem-somos",
    meta: indexzdMGiK8nGcMeta || {},
    alias: indexzdMGiK8nGcMeta?.alias || [],
    redirect: indexzdMGiK8nGcMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/quem-somos/index.vue").then(m => m.default || m)
  },
  {
    name: indexYE47yhaG7xMeta?.name ?? "solucoes-de-crm___pt",
    path: indexYE47yhaG7xMeta?.path ?? "/solucoes-de-crm",
    meta: indexYE47yhaG7xMeta || {},
    alias: indexYE47yhaG7xMeta?.alias || [],
    redirect: indexYE47yhaG7xMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/solucoes-de-crm/index.vue").then(m => m.default || m)
  },
  {
    name: indexYE47yhaG7xMeta?.name ?? "solucoes-de-crm___en",
    path: indexYE47yhaG7xMeta?.path ?? "/en/solucoes-de-crm",
    meta: indexYE47yhaG7xMeta || {},
    alias: indexYE47yhaG7xMeta?.alias || [],
    redirect: indexYE47yhaG7xMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/solucoes-de-crm/index.vue").then(m => m.default || m)
  },
  {
    name: indexYE47yhaG7xMeta?.name ?? "solucoes-de-crm___es",
    path: indexYE47yhaG7xMeta?.path ?? "/es/solucoes-de-crm",
    meta: indexYE47yhaG7xMeta || {},
    alias: indexYE47yhaG7xMeta?.alias || [],
    redirect: indexYE47yhaG7xMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/solucoes-de-crm/index.vue").then(m => m.default || m)
  },
  {
    name: indexXj4fL5WcUeMeta?.name ?? "sustentacao-de-sistemas___pt",
    path: indexXj4fL5WcUeMeta?.path ?? "/sustentacao-de-sistemas",
    meta: indexXj4fL5WcUeMeta || {},
    alias: indexXj4fL5WcUeMeta?.alias || [],
    redirect: indexXj4fL5WcUeMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/sustentacao-de-sistemas/index.vue").then(m => m.default || m)
  },
  {
    name: indexXj4fL5WcUeMeta?.name ?? "sustentacao-de-sistemas___en",
    path: indexXj4fL5WcUeMeta?.path ?? "/en/sustentacao-de-sistemas",
    meta: indexXj4fL5WcUeMeta || {},
    alias: indexXj4fL5WcUeMeta?.alias || [],
    redirect: indexXj4fL5WcUeMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/sustentacao-de-sistemas/index.vue").then(m => m.default || m)
  },
  {
    name: indexXj4fL5WcUeMeta?.name ?? "sustentacao-de-sistemas___es",
    path: indexXj4fL5WcUeMeta?.path ?? "/es/sustentacao-de-sistemas",
    meta: indexXj4fL5WcUeMeta || {},
    alias: indexXj4fL5WcUeMeta?.alias || [],
    redirect: indexXj4fL5WcUeMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/sustentacao-de-sistemas/index.vue").then(m => m.default || m)
  },
  {
    name: indexXlI5zFw6jMMeta?.name ?? "termos-e-condicoes-de-uso___pt",
    path: indexXlI5zFw6jMMeta?.path ?? "/termos-e-condicoes-de-uso",
    meta: indexXlI5zFw6jMMeta || {},
    alias: indexXlI5zFw6jMMeta?.alias || [],
    redirect: indexXlI5zFw6jMMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/termos-e-condicoes-de-uso/index.vue").then(m => m.default || m)
  },
  {
    name: indexXlI5zFw6jMMeta?.name ?? "termos-e-condicoes-de-uso___en",
    path: indexXlI5zFw6jMMeta?.path ?? "/en/termos-e-condicoes-de-uso",
    meta: indexXlI5zFw6jMMeta || {},
    alias: indexXlI5zFw6jMMeta?.alias || [],
    redirect: indexXlI5zFw6jMMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/termos-e-condicoes-de-uso/index.vue").then(m => m.default || m)
  },
  {
    name: indexXlI5zFw6jMMeta?.name ?? "termos-e-condicoes-de-uso___es",
    path: indexXlI5zFw6jMMeta?.path ?? "/es/termos-e-condicoes-de-uso",
    meta: indexXlI5zFw6jMMeta || {},
    alias: indexXlI5zFw6jMMeta?.alias || [],
    redirect: indexXlI5zFw6jMMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/termos-e-condicoes-de-uso/index.vue").then(m => m.default || m)
  },
  {
    name: indexuLsvQHRH9sMeta?.name ?? "ux-ui___pt",
    path: indexuLsvQHRH9sMeta?.path ?? "/ux-ui",
    meta: indexuLsvQHRH9sMeta || {},
    alias: indexuLsvQHRH9sMeta?.alias || [],
    redirect: indexuLsvQHRH9sMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/ux-ui/index.vue").then(m => m.default || m)
  },
  {
    name: indexuLsvQHRH9sMeta?.name ?? "ux-ui___en",
    path: indexuLsvQHRH9sMeta?.path ?? "/en/ux-ui",
    meta: indexuLsvQHRH9sMeta || {},
    alias: indexuLsvQHRH9sMeta?.alias || [],
    redirect: indexuLsvQHRH9sMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/ux-ui/index.vue").then(m => m.default || m)
  },
  {
    name: indexuLsvQHRH9sMeta?.name ?? "ux-ui___es",
    path: indexuLsvQHRH9sMeta?.path ?? "/es/ux-ui",
    meta: indexuLsvQHRH9sMeta || {},
    alias: indexuLsvQHRH9sMeta?.alias || [],
    redirect: indexuLsvQHRH9sMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/src/pages/ux-ui/index.vue").then(m => m.default || m)
  }
]